import settings from '@/settings'

const post = (data) => {
  return fetch(`${settings.apetrusApi}/sendzapi/notificar-administrador-empresa/`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  })
}

export default {
  post
}
