<template>
  <v-dialog
      v-model="ativarDialog"
      width="40em"
      :fullscreen="$vuetify.breakpoint.xs"
      hide-overlay
      scrollable
      @click:outside="
          ativarDialog = false
      "
      @keydown.esc="
          ativarDialog = false
      "
  >
      <template v-slot:activator="{ on }">
        <v-btn class="hover-button" color="secondary" block v-on="on">TENHO INTERESSE</v-btn>
      </template>
      <v-card rounded="5">
          <v-card-title class="justify-center">
            <v-btn
              icon
              @click="ativarDialog = false"
              absolute
              style="left: 1em;"
              small
            >
              <v-icon>close</v-icon>
            </v-btn>
            <h3>ESTÁ INTERESSADO NESSE VEÍCULO?</h3>
          </v-card-title>
          <v-card-text style="font-size: 1.2em;">
            Deixe seu dados abaixo e o vendedor entrar em contato com você
          </v-card-text>
          <v-divider></v-divider>
          <v-alert v-model="alerta.ativo" :type="alerta.tipo" style="width: 100%;">
              {{ alerta.texto }}
          </v-alert>
          <v-card-text>
            <v-form lazy-validation v-model="valido" ref="form" class="mt-4">
                <v-text-field
                    label="Nome"
                    placeholder="Dígite seu nome"
                    outlined
                    required
                    rounded
                    v-model="data.nome"
                    :rules="[
                        (v) => !!v || 'Esse campo é obrigatório'
                    ]"
                ></v-text-field>
                <v-text-field
                    label="E-mail"
                    placeholder="Dígite seu E-mail"
                    outlined
                    rounded
                    required
                    v-model="data.email"
                    @input="data.email = data.email.toLowerCase().replaceAll(' ', '').trim()"
                    :rules="[
                        (v) => !!v || 'Esse campo é obrigatório',
                        v => {
                          const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                          return pattern.test(v) || 'E-mail inválido.'
                        }
                    ]"
                ></v-text-field>
                <v-text-field
                    label="Telefone"
                    outlined
                    rounded
                    required
                    v-model="data.telefone"
                    v-mask="'(NN) N NNNN-NNNN'"
                    placeholder="Dígite seu Telefone"
                    :rules="[
                        (v) => !!v || 'Esse campo é obrigatório'
                    ]"
                ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center">
              <v-btn
                color="secondary"
                block
                rounded
                class="mb-4 hover-button"
                :disabled="!data.nome || !data.telefone || !data.email || !valido || carregando"
                @click="confirmar_interesse"
                :loading="carregando"
              >
                Confirmar
              </v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import sendzapi from '@/api/sendzapi'

export default {
  props: ['veiculo'],
  data: () => ({
    valido: false,
    ativarDialog: false,
    data: {
      nome: undefined,
      telefone: undefined,
      email: undefined,
      empresa: null
    },
    carregando: false,
    alerta: {
      tipo: 'error',
      texto: 'Esse tipo de veículo já foi cadastrado',
      ativo: false
    }
  }),
  watch: {
    ativarDialog (val) {
      if (!val) {
        this.$refs.form.reset()
        this.tipoSelecionado = undefined
      }
    }
  },
  methods: {
    confirmar_interesse () {
      this.carregando = true
      // this.data.telefone = this.data.telefone.replace(/\D/g, '')
      this.sendzapi_api().then(() => {
        this.ativarAlerta('Em brese um vendedor entrará em contato com você.', 'success')
        this.carregando = false
      })
    },
    sendzapi_api () {
      return new Promise((resolve, reject) => {
        sendzapi.post({
          ...this.data,
          empresa: this.veiculo.empresa.cnpj,
          id_veiculo: this.veiculo.id_veiculo
        }).then(response => {
          if (!response.ok) {
            this.ativarAlerta('Não foi possível estabelecer uma conexão com o vendedor.', 'error')
            reject(Error('Não foi possível estabelecer uma conexão com o vendedor.'))
          }
          resolve('OK')
        })
      })
    },
    ativarAlerta (texto, tipo) {
      this.alerta.texto = texto
      this.alerta.tipo = tipo
      this.alerta.ativo = true
      const interval = setInterval(() => {
        this.alerta.ativo = false
        this.ativarDialog = false
        clearInterval(interval)
      }, 5000)
    }
  }
}
</script>

<style>
  .hover-button:hover .v-btn__content {
    color: white !important;
  }
  .v-application .hover-button:hover .v-btn__content {
    color: white !important;
  }

</style>
