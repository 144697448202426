<template>
  <v-app-bar
    color="#fff"
    class="elevation-8 pa-0 ma-0"
    :height="$vuetify.breakpoint.xs ? menu ? '650em' : '380em' : '200em'"
    dense
    shrink-on-scroll
  >
  <v-layout
    class="black ma-0"
    row
    wrap
    :justify-end="!$vuetify.breakpoint.xs"
    :style="{
      'width': '100%',
      'height': $vuetify.breakpoint.xs ? menu ? '42%' : '10%' : '22%',
      'align-items': 'center',
      'display': 'flex'
    }">
     <template v-if="!$vuetify.breakpoint.xs">
      <v-btn
        text
        color="secontary"
        dark
        small
        class="links"
        href="https://conectarevendas.com.br/"
        target="_blank"
      >
        Página Inicial
      </v-btn>
      <v-btn
        v-if="$route.path !== '/revendas'"
        text
        color="secontary"
        dark
        small
        class="links"
        @click="$router.push('/revendas')"
      >
        Revendas
      </v-btn>
      <v-btn
        v-else
        text
        color="secontary"
        dark
        small
        class="links"
        @click="$router.push('/')"
      >
        Veículos
      </v-btn>
      <v-btn
        text
        color="secontary"
        dark
        small
        class="links"
        href="https://conectarevendas.com.br/blog/"
        target="_blank"
      >
        Blog
      </v-btn>
      <v-btn
        text
        color="secontary"
        dark
        small
        class="links"
        href="https://conectarevendas.com.br/duvidas/"
        target="_blank"
      >
        Perguntas Frequentes
      </v-btn>
      <v-btn
        text
        color="secontary"
        dark
        small
        class="links"
        href="https://conectarevendas.com.br/contato/"
        target="_blank"
      >
        Contato
      </v-btn>
    </template>
    <template v-else>
      <v-btn icon color="white" @click="menu = !menu">
        <v-icon>menu</v-icon>
      </v-btn>
      <template v-if="menu">
        <v-list class="black" style="width: 100%;" dark>
          <v-list-item href="https://conectarevendas.com.br/">
            <v-list-item-title class="item-lista">
              Página Inicial
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/revendas')" v-if="$route.path !== '/revendas'">
            <v-list-item-title class="item-lista">
              Revendas
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push('/')" v-else>
            <v-list-item-title class="item-lista">
              Veículos
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="https://conectarevendas.com.br/blog/">
            <v-list-item-title class="item-lista">
              Blog
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="https://conectarevendas.com.br/duvidas/">
            <v-list-item-title class="item-lista">
              Perguntas Frequentes
            </v-list-item-title>
          </v-list-item>
          <v-list-item href="https://conectarevendas.com.br/contato/">
            <v-list-item-title class="item-lista">
              Contato
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </template>
  </v-layout>
  <v-layout justify-space-between wrap :style="{
      'width':' 100%',
      'height':  menu ? '58%' : '78%'
    }"
  >
    <v-flex xs12 sm4 md3 class="mb-4 allcenter">
      <v-img
        :src="require('@/assets/logo01.png')"
        contain
        :height="$vuetify.breakpoint.xl ? '90%' : '100%'"
      ></v-img>
    </v-flex>
    <v-flex xs12 sm8 md9 class="allcenter mt-4">
      <v-layout justify-space-around wrap>
        <v-flex xs12 sm5 class="mb-4 allcenter">
          <v-btn
            color="primary"
            block
            :large="!$vuetify.breakpoint.sm"
            :small="$vuetify.breakpoint.sm"
            href="https://conectarevendas.com.br/login"
            target="_blank"
            class="botao"
          >
            Cadastre seu veículo
          </v-btn>
        </v-flex>
        <v-flex xs12 sm5 class="mb-4 allcenter">
          <v-btn
            color="primary"
            block
            :large="!$vuetify.breakpoint.sm"
            :small="$vuetify.breakpoint.sm"
            href="https://conectarevendas.com.br/formulario-para-revenda/"
            target="_blank"
            class="botao"
          >
            Cadastre sua revenda
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
  </v-app-bar>
</template>
<script>
export default {
  data: () => ({
    menu: false
  })
}
</script>

<style>
  .v-btn__content {
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased
  }
  .links {
    font-family: 'harabara', sans-serif;
    font-weight: 600 !important;
    font-size: 0.6em !important;
  }
  .botao {
    font-family: 'Aileron', sans-serif  !important;
    font-weight: 900  !important;
    font-size: 0.6em  !important;
  }
  .v-btn__content:hover {
    color: var(--v-secondary-base) !important;
  }
  .v-toolbar__content {
    padding: 0px !important;
    width: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
  }
  item-lista {
    font-family: 'harabara', sans-serif;
    font-weight: 600 !important;
    font-size: 0.6em !important;
  }
  .item-lista:hover {
    background-color: gray !important;
    padding: 0.7em;
  }
</style>
