var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"40em","fullscreen":_vm.$vuetify.breakpoint.xs,"hide-overlay":"","scrollable":""},on:{"click:outside":function($event){_vm.ativarDialog = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.ativarDialog = false}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hover-button",attrs:{"color":"secondary","block":""}},on),[_vm._v("TENHO INTERESSE")])]}}]),model:{value:(_vm.ativarDialog),callback:function ($$v) {_vm.ativarDialog=$$v},expression:"ativarDialog"}},[_c('v-card',{attrs:{"rounded":"5"}},[_c('v-card-title',{staticClass:"justify-center"},[_c('v-btn',{staticStyle:{"left":"1em"},attrs:{"icon":"","absolute":"","small":""},on:{"click":function($event){_vm.ativarDialog = false}}},[_c('v-icon',[_vm._v("close")])],1),_c('h3',[_vm._v("ESTÁ INTERESSADO NESSE VEÍCULO?")])],1),_c('v-card-text',{staticStyle:{"font-size":"1.2em"}},[_vm._v(" Deixe seu dados abaixo e o vendedor entrar em contato com você ")]),_c('v-divider'),_c('v-alert',{staticStyle:{"width":"100%"},attrs:{"type":_vm.alerta.tipo},model:{value:(_vm.alerta.ativo),callback:function ($$v) {_vm.$set(_vm.alerta, "ativo", $$v)},expression:"alerta.ativo"}},[_vm._v(" "+_vm._s(_vm.alerta.texto)+" ")]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"mt-4",attrs:{"lazy-validation":""},model:{value:(_vm.valido),callback:function ($$v) {_vm.valido=$$v},expression:"valido"}},[_c('v-text-field',{attrs:{"label":"Nome","placeholder":"Dígite seu nome","outlined":"","required":"","rounded":"","rules":[
                      function (v) { return !!v || 'Esse campo é obrigatório'; }
                  ]},model:{value:(_vm.data.nome),callback:function ($$v) {_vm.$set(_vm.data, "nome", $$v)},expression:"data.nome"}}),_c('v-text-field',{attrs:{"label":"E-mail","placeholder":"Dígite seu E-mail","outlined":"","rounded":"","required":"","rules":[
                      function (v) { return !!v || 'Esse campo é obrigatório'; },
                      function (v) {
                        var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                        return pattern.test(v) || 'E-mail inválido.'
                      }
                  ]},on:{"input":function($event){_vm.data.email = _vm.data.email.toLowerCase().replaceAll(' ', '').trim()}},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(NN) N NNNN-NNNN'),expression:"'(NN) N NNNN-NNNN'"}],attrs:{"label":"Telefone","outlined":"","rounded":"","required":"","placeholder":"Dígite seu Telefone","rules":[
                      function (v) { return !!v || 'Esse campo é obrigatório'; }
                  ]},model:{value:(_vm.data.telefone),callback:function ($$v) {_vm.$set(_vm.data, "telefone", $$v)},expression:"data.telefone"}})],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"mb-4 hover-button",attrs:{"color":"secondary","block":"","rounded":"","disabled":!_vm.data.nome || !_vm.data.telefone || !_vm.data.email || !_vm.valido || _vm.carregando,"loading":_vm.carregando},on:{"click":_vm.confirmar_interesse}},[_vm._v(" Confirmar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }